<template>

    <NavBar></NavBar>
    <div class="main-content">

        <CarouselGallery></CarouselGallery>


    </div>
    <!-- main content -->

    <!-- card1 -->
    <div class="container">
        <h3 class="text-center" style="padding-top: 30px;">SERVICES WE OFFER</h3>
        <div class="row" style="margin-top: 50px;">
            <div class="col-md-4 py-3 py-md-0">
                <div class="card">
                    <img src="../assets/image/c1.png" alt="" class="card image-top" height="200px">
                    <div class="card-body">
                        <h5 class="card-titel text-center">CUSTOM MENUS</h5>
                        <p class="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                            ipsam vitae facere eius modi iure possimus, soluta ea inventore. Omnis!</p>
                        <div id="btn2" class="text-center"><button>View More</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 py-3 py-md-0">
                <div class="card">
                    <img src="../assets/image/c2.png" alt="" class="card image-top" height="200px">
                    <div class="card-body">
                        <h5 class="card-titel text-center">SMARTEST WAY</h5>
                        <p class="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                            ipsam vitae facere eius modi iure possimus, soluta ea inventore. Omnis!</p>
                        <div id="btn2" class="text-center"><button>View More</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 py-3 py-md-0">
                <div class="card">
                    <img src="../assets/image/c3.png" alt="" class="card image-top" height="200px">
                    <div class="card-body">
                        <h5 class="card-titel text-center">USER FRIENDLEY</h5>
                        <p class="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                            ipsam vitae facere eius modi iure possimus, soluta ea inventore. Omnis!</p>
                        <div id="btn2" class="text-center"><button>View More</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- card1 -->

    <!-- card2 -->
    <div class="container">
        <div class="row" style="margin-top: 100px;">
            <div class="col-md-4 py-3 py-md-0">
                <div class="card" id="tpc">
                    <img src="../assets/image/ch.png" alt="" class="card image-top">
                    <div class="card-img-overlay">
                        <h4 class="card-titel">Best Chair</h4>
                        <p class="card-text">Lorem ipsum dolor.</p>
                        <div id="btn2"><button>View More</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 py-3 py-md-0">
                <div class="card" id="tpc">
                    <img src="../assets/image/sf.png" alt="" class="card image-top">
                    <div class="card-img-overlay">
                        <h4 class="card-titel">Sofa</h4>
                        <p class="card-text">Lorem ipsum dolor.</p>
                        <div id="btn2"><button>View More</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 py-3 py-md-0">
                <div class="card" id="tpc">
                    <img src="../assets/image/work desk.png" alt="" class="card image-top">
                    <div class="card-img-overlay">
                        <h4 class="card-titel">Work Desk</h4>
                        <p class="card-text">Lorem ipsum dolor.</p>
                        <div id="btn2"><button>View More</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- card2 -->

    <!-- card3 -->
    <div class="container">
        <h3 class="text-center" style="margin-top: 50px;">TREANDLY PRODUCTS</h3>
        <div class="row" style="margin-top: 50px;">
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card1.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">Best Sofa</h3>
                        <p class="card-text text-center">$1000.50</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card2.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">New Sofa</h3>
                        <p class="card-text text-center">$100.50</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card3.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">New Chair</h3>
                        <p class="card-text text-center">$300.20</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card4.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">Modern Chair</h3>
                        <p class="card-text text-center">$500.50</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row" style="margin-top: 50px;">
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card5.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">Best Sofa</h3>
                        <p class="card-text text-center">$200.50</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card6.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">Sofa Chair</h3>
                        <p class="card-text text-center">$100.50</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card1.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">Table Chair</h3>
                        <p class="card-text text-center">$150.50</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 py-3 py-md-0">
                <div class="card" id="c">
                    <img src="../assets/image/card1.png" alt="" class="card image-top">
                    <div class="card-body">
                        <h3 class="card-titel text-center">Hanging Chair</h3>
                        <p class="card-text text-center">$500.60</p>
                        <div id="btn3"><button>Shop Now</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- card3 -->

    <!-- about -->
    <div class="container">
        <h1 class="text-center" style="margin-top: 50px;">ABOUT</h1>
        <div class="row" style="margin-top: 50px;">
            <div class="col-md-6 py-3 py-md-0">
                <div class="card">
                    <img src="../assets/image/about.png" alt="">
                </div>
            </div>
            <div class="col-md-6 py-3 py-md-0">
                <p>Tappizzo, a distinguished brand of FloorNMore India, is renowned for its exquisite handwoven carpets,
                    crafted meticulously in India and marketed across the USA. Our diverse collection of carpet styles
                    reflects a blend of traditional craftsmanship and modern aesthetics, offering an extensive range of
                    options to suit various interior design needs. From classic patterns like ZEST and VENICE to
                    contemporary styles such as TORO STRIPES and TORO PYRAMID, our collection caters to a wide spectrum
                    of tastes and preferences. Each carpet style, including TIMOR, TIFFANY, SUMATRA, and SEVILLE, is
                    designed with a unique touch, ensuring that every piece not only enhances the beauty of a space but
                    also provides exceptional durability and comfort. Our commitment to quality and design excellence is
                    evident in every style, from the intricate details of TORO DIAMOND and TORO BOLD to the elegant
                    simplicity of PASHA and PARIS. Whether you're seeking a statement piece or a subtle complement to
                    your décor, Tappizzo offers a range of options like OSLO, OLYMPIA, and NEPAL to meet your needs.
                    Embrace the beauty and craftsmanship of Tappizzo carpets, where each style is a testament to our
                    dedication to creating timeless, luxurious flooring solutions.</p>
                <div id="btn4"><button>Read More...</button></div>
            </div>
        </div>
    </div>

</template>

<script>

import NavBar from './NavBar.vue';
import CarouselGallery from './CarouselGallery.vue'
export default {
    name: 'HomePage',
    components: {
        NavBar,
        CarouselGallery,
    },
    data() {
        return {
            cor: false,
        }
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

/* navbar top */
.navbar-top {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

.social-link i img {
    margin-left: 10px;
    transition: 0.5s ease;
    cursor: pointer;
}

.social-link i img:hover {
    background-color: rgba(238, 224, 208);
    border-radius: 50px;
}

@media screen and (max-width:420px) {
    .social-link i img {
        width: 20px;
    }
}

.logo h3 {
    font-weight: bold;
}

@media screen and (max-width:420px) {
    .logo h3 {
        font-size: 20px;
    }
}

.icons i img {
    margin-left: 10px;
    cursor: pointer;
}

@media screen and (max-width:420px) {
    .icons i img {
        width: 20px;
    }
}

/* navbar top */

/* main content */
.main-content {
    width: 100%;
    height: 90vh;
    background-size: cover;
    background-position: 70%;
}

/* navbar */
#navbar-color {
    background-color: black;
}

.navbar-nav {
    margin: auto;
}

.nav-link {
    margin-left: 10px;
    font-weight: bold;
    color: white;
    transition: 0.5s ease;
    cursor: pointer;
}

.nav-link:hover {
    color: green;
}

/* navbar */

/* content */
.content {
    margin-top: 150px;
    margin-left: 60px;
}

.content h1 {
    font-size: 50px;
}

#btn1 button {
    width: 150px;
    height: 32px;
    background: transparent;
    border: 1px solid black;
    letter-spacing: 3px;
    font-weight: bold;
    transition: 0.5s ease;
    cursor: pointer;
}

#btn1 button:hover {
    background-color: green;
}

@media screen and (max-width:420px) {
    .content h1 {
        font-size: 30px;
    }
}

/* content */

/* main content */

/* card1 */
#btn2 button {
    width: 100px;
    height: 30px;
    background: transparent;
    border: 1px solid black;
    transition: 0.5s ease;
    cursor: pointer;
}

#btn2 button:hover {
    background-color: rgba(238, 224, 208);
}

/* card1 */

/* card2 */
#tpc {
    box-shadow: 0 0 5px black;
    transition: 0.5s ease;
    cursor: pointer;
}

#tpc:hover {
    transform: translateY(-10px);
}

/* card2 */

/* card3 */
#c p {
    font-weight: bold;
}

#btn3 {
    text-align: center;
}

#btn3 button {
    width: 150px;
    height: 30px;
    background: transparent;
    border: 1px solid black;
    transition: 0.5s ease;
    cursor: pointer;
}

#btn3 button:hover {
    background-color: rgba(238, 224, 208);
}

@media screen and (max-width:895px) {
    #btn3 button {
        width: 100px;
    }
}

/* card3 */
#btn4 button {
    width: 150px;
    height: 30px;
    background: transparent;
    border: 1px solid black;
    transition: 0.5s ease;
    cursor: pointer;
}

#btn4 button:hover {
    background-color: rgba(238, 224, 208);
}

/* footer */
/* #footer{
    width: 100%;
    background-color: black;
    margin-top: 100px;
}
#footer h1{
    color: white;
    padding-top: 30px;
}
#footer p{
    color: white;
} */
.icons i {
    color: rgba(238, 224, 208);
    font-size: 30px;
    cursor: pointer;
}

.copyright {
    color: white;
    margin-top: 20px;
}

.credite {
    color: white;
}

/* footer */
</style>