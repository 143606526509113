<template>
  <div class="carousel-container">
    <div class="carousel-slide" ref="carouselSlide">
      <img v-for="(image, index) in images" :key="index" :src="require(`@/${image.src}`)" :alt="image.alt">
    </div>
    <div class="carousel-buttons">
      <button class="prev" @click="prevSlide">&lt;</button>
      <button class="next" @click="nextSlide">&gt;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarouselGallery',
  data() {
    return {
      images: [
        { src: 'assets/image1.jpg', alt: 'Image 1' },
        { src: 'assets/image2.jpg', alt: 'Image 2' },
        { src: 'assets/image3.jpg', alt: 'Image 3' },
        { src: 'assets/image4.jpg', alt: 'Image 4' }
      ],
      counter: 0,
      interval: null
    };
  },
  mounted() {
    this.setSlideWidth();
    window.addEventListener('resize', this.setSlideWidth);
    this.startAutoSlide();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setSlideWidth);
    clearInterval(this.interval);
  },
  methods: {
    setSlideWidth() {
      const size = this.$refs.carouselSlide.children[0].clientWidth;
      this.$refs.carouselSlide.style.transform = `translateX(${-size * this.counter}px)`;
    },
    updateSlidePosition() {
      const size = this.$refs.carouselSlide.children[0].clientWidth;
      this.$refs.carouselSlide.style.transform = `translateX(${-size * this.counter}px)`;
    },
    nextSlide() {
      if (this.counter >= this.images.length - 1) this.counter = -1;
      this.counter++;
      this.updateSlidePosition();
    },
    prevSlide() {
      if (this.counter <= 0) this.counter = this.images.length;
      this.counter--;
      this.updateSlidePosition();
    },
    startAutoSlide() {
      this.interval = setInterval(() => {
        this.nextSlide();
      }, 1600);
    }
  }
};
</script>


<style scoped>
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}

.carousel-slide {
  display: flex;
  width: 400%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: none; /* Hide buttons */
  justify-content: space-between;
  transform: translateY(-50%);
}

.prev,
.next {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
</style>
