<template>
    <parennav class="sticky max-w-screen overflow-hidden top-0 z-20 ">
        <nav class="relative bg-white shadow-sm w-full top-0 py-1 ">
            <div class="w-full mx-auto flex flex-wrap items-center justify-between mt-0 px-6 py-1"><button
                    class="md:hidden navbar-burger flex items-center text-black "><svg width="30" height="30"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" hidden="">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M19 5L5 19M5 5L9.5 9.5M12 12L19 19" stroke="#000000" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg><svg width="25" height="25" viewBox="0 -0.5 25 25" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M5.5 11.75C5.08579 11.75 4.75 12.0858 4.75 12.5C4.75 12.9142 5.08579 13.25 5.5 13.25V11.75ZM19.5 13.25C19.9142 13.25 20.25 12.9142 20.25 12.5C20.25 12.0858 19.9142 11.75 19.5 11.75V13.25ZM5.5 7.75C5.08579 7.75 4.75 8.08579 4.75 8.5C4.75 8.91421 5.08579 9.25 5.5 9.25V7.75ZM14.833 9.25C15.2472 9.25 15.583 8.91421 15.583 8.5C15.583 8.08579 15.2472 7.75 14.833 7.75V9.25ZM5.5 15.75C5.08579 15.75 4.75 16.0858 4.75 16.5C4.75 16.9142 5.08579 17.25 5.5 17.25V15.75ZM14.833 17.25C15.2472 17.25 15.583 16.9142 15.583 16.5C15.583 16.0858 15.2472 15.75 14.833 15.75V17.25ZM5.5 13.25H19.5V11.75H5.5V13.25ZM5.5 9.25H14.833V7.75H5.5V9.25ZM5.5 17.25H14.833V15.75H5.5V17.25Z"
                                fill="#000000"></path>
                        </g>
                    </svg></button><input class="hidden" type="checkbox" id="menu-toggle">
                <div class="hidden md:flex md:items-center md:w-auto transition-all duration-900 transform w-full order-3 md:order-2"
                    id="menu">
                    <nav>
                        <ul
                            class="md:flex items-center font-futura justify-between text-base text-gray-700 pt-4 md:pt-0">
                            <li><a
                                    class="cursor-pointer inline-block no-underline hover:text-black hover:bg-gray-100 rounded-full py-1 px-4 font-bold">Home</a>
                            </li>
                            <li><a
                                    class="cursor-pointer inline-block no-underline hover:bg-gray-100 rounded-full py-1 px-4 font-bold">Fashion</a>
                            </li>
                            <li><a
                                    class="cursor-pointer inline-block no-underline hover:bg-gray-100 rounded-full py-1 px-4 font-bold">Handicrafts</a>
                            </li>
                            <li><a
                                    class="cursor-pointer inline-block no-underline hover:bg-gray-100 rounded-full py-1 px-4 font-bold">Home
                                    Decor</a></li>
                            <li><a
                                    class="cursor-pointer inline-block no-underline hover:bg-gray-100 rounded-full py-1 px-4 font-bold">Jewelry</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="order-1 md:order-1"><a
                        class="cursor-pointer flex my-auto justify-center font-satisfy items-center tracking-wide no-underline hover:no-underline font-bold text-black text-2xl "
                        href="/"><img src="../assets/logo.png" class="pb-1 h-14 rotate-[30deg] mx-2"><span
                            class="my-auto h-full">FloorsNMore</span></a></div>
                <div class="font-futura2 md:order-3 hidden lg:flex">
                    <form><label for="default-search"
                            class="text-sm font-medium text-white bg-black sr-only ">Search</label>
                        <div class="relative flex gap-2"><input type="search" name="query" id="query"
                                class="block px-4 py-2 w-full text-sm text-black bg-gray-50 rounded-md border border-gray-300"
                                placeholder="Search over 1000s of items..." required="" value=""><button type="submit"
                                class=" right-2.5 top-1 my-auto border bg-black hover:bg-gray-800 text-white border-black font-futura font-semibold rounded-md text-sm px-4 py-2 ">Search</button>
                        </div>
                    </form>
                </div>
                <div class="order-3 md:order-4 flex items-center" id="nav-content"><a
                        class="cursor-pointer lg:hidden mr-4 inline-block no-underline hover:text-black"><svg
                            class="h-6 xl:h-8" width="20" height="20" aria-hidden="true" focusable="false"
                            data-prefix="far" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z">
                            </path>
                        </svg></a><a href="/login"><svg class="fill-current hover:text-black"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <circle fill="none" cx="12" cy="7" r="3"></circle>
                            <path
                                d="M12 2C9.243 2 7 4.243 7 7s2.243 5 5 5 5-2.243 5-5S14.757 2 12 2zM12 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3S13.654 10 12 10zM21 21v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h2v-1c0-2.757 2.243-5 5-5h4c2.757 0 5 2.243 5 5v1H21z">
                            </path>
                        </svg></a><a class="pl-3 cursor-pointer inline-block no-underline hover:text-black"><svg
                            class="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" viewBox="0 0 24 24">
                            <path
                                d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8 c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z M17.341,14h-6.697L8.371,9 h11.112L17.341,14z">
                            </path>
                            <circle cx="10.5" cy="18.5" r="1.5"></circle>
                            <circle cx="17.5" cy="18.5" r="1.5"></circle>
                        </svg></a></div>
            </div>
        </nav>
    </parennav>
</template>

<script>
export default {
    name: 'NavBar2',

}
</script>