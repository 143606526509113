import { createRouter, createWebHistory } from 'vue-router'

import HomePage from './components/HomePage.vue'
import ContactPage from './components/ContactPage.vue'
import RecommendationPage from './components/RecommendationPage.vue'
import SignUp from './components/SignUp.vue'
import UserLogin from './components/UserLogin.vue'
import UserProfile from './components/UserProfile.vue'
import ProductPage from './components/ProductPage.vue'
import ShopPage from './components/ShopPage.vue'
import ProductPage2 from './components/ProductPage2.vue'
import ProductPage3 from './components/ProductPage3.vue'


const routes = [
    {
        name: 'Home',
        component: HomePage,
        path: '/'
    },
    {
        name: 'Contact',
        component: ContactPage,
        path: '/contact'
    },
    {
        name: 'Recommendation',
        component: RecommendationPage,
        path: '/recommand'
    },
    {
        name: 'SignUp',
        component: SignUp,
        path: '/register'
    },
    {
        name: 'UserLogin',
        component: UserLogin,
        path: '/login'
    },
    {
        name: 'UserProfile',
        component: UserProfile,
        path: '/profile'
    },
    {
        name: 'ProductPage2',
        component: ProductPage2,
        path: '/product/:type/:sku',
        props : true,
    },
    {
        name: 'ProductPage',
        component: ProductPage,
        path: '/productx/:type/:sku',
        props : true,
    },
    {
        name: 'ProductPage3',
        component: ProductPage3,
        path: '/carpet/:slug',
        props : true,
    },
    {
        name: 'ShopPage',
        component: ShopPage,
        path: '/shop'
    },
    
    

    
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router