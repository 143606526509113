<template>
    <div>
      <h3 style="text-align: center;">Capture Image</h3>
      <video ref="videoElement" autoplay></video>
      <button @click="captureImage">Capture</button>
      <img :src="imageUrl" v-if="imageUrl" alt="Captured Image" class="captured-image" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageCapture',
    data() {
      return {
        imageUrl: null,
      };
    },
    methods: {
      async initCamera() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          this.$refs.videoElement.srcObject = stream;
        } catch (error) {
          console.error('Error accessing camera:', error);
        }
      },
      captureImage() {
        const canvas = document.createElement('canvas');
        canvas.width = this.$refs.videoElement.videoWidth;
        canvas.height = this.$refs.videoElement.videoHeight;
        canvas.getContext('2d').drawImage(this.$refs.videoElement, 0, 0);
        this.imageUrl = canvas.toDataURL('image/png');
      },
    },
    mounted() {
      this.initCamera();
    },
  };
  </script>
  
  <style scoped>
  .video-element {
    width: 100%;
  }
  
  .captured-image {
    width: 300px;
    height: auto;
    margin-top: 20px;
  }
  </style>
  