<template>
    
    <NavBar></NavBar>
    <h3> Hello </h3>
</template>

<script>
import NavBar from './NavBar.vue'
export default {
    name : 'UserPage',
    components : {
        NavBar,
    },
    mounted() {
    const token = localStorage.getItem('Auth-Token');
    if (!token) {
      this.$router.push({ name: 'UserLogin' });
    } else {
      // Assuming the profile page route is named 'UserProfile'
      this.$router.push({ name: 'UserProfile' });
    }
  },
}

</script>