<template>
    <NavBar></NavBar>
    <div class="container">
        <h3 class="text-center" style="margin-top: 50px;">Find a perfect product from our best collection</h3>
        <div class="row" style="margin-top: 50px;">
            <div v-for="product in products" :key="product.slug" class="col-md-3 py-3 py-md-5">
                <div class="card">
                    <img :src="product.image1" alt="" class="card-img-top">
                    <div class="card-body">
                        <h3 class="card-title text-center">{{ product.style_name }}</h3>
                        <!-- Add a placeholder price for demonstration, you can modify it based on your actual data -->
                        <p class="card-text text-center">$123.45</p>
                        <button v-on:click="sendToProductPage(product.slug)"
                            style="background-color: black; color: white; padding: 3%; margin-left: 25%; justify-content: center;">
                            Shop Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from './NavBar.vue';
import axios from 'axios';

export default {
    name: 'ShopPage',
    components: {
        NavBar,
    },
    data() {
        return {
            products: [],
        };
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {
        fetchProducts() {
            axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/carpets/`)
                .then(response => {
                    this.products = response.data;
                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                    alert('Error fetching products from the backend');
                });
        },
        sendToProductPage(slug) {
            this.$router.push({ name: 'ProductPage3', params: { slug: slug } });
        },
    }
}
</script>

<style>
.container {
    padding: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card img {
    width: 100%;
    height: auto;
}

.card-title {
    font-size: 1.5em;
    margin: 10px 0;
}

.card-text {
    font-size: 1.2em;
    margin: 10px 0;
}

button {
    background-color: black;
    color: white;
    padding: 3%;
    margin-left: 25%;
    border: none;
    cursor: pointer;
    display: block;
    width: 50%;
    margin: 0 auto;
}

button:hover {
    background-color: #555;
}
</style>
