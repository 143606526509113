<template>
    <div>
      <h3 style="text-align: center;">Upload Image</h3>
      <input type="file" @change="onFileChange">
      <img :src="imageUrl" v-if="imageUrl" alt="Uploaded Image" class="uploaded-image" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageUpload',
    data() {
      return {
        imageUrl: null,
      };
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .uploaded-image {
    width: 300px;
    height: auto;
    margin-top: 20px;
  }
  </style>
  