<template>
    <NavBar></NavBar>
    <div class="signup">
      <h1 class="text-2xl font-bold text-center mb-4">Registration</h1>
      <div class="register">
        <form @submit.prevent="registerUser">
          <input v-model="name" type="text" style="text-align: center;" required="true" placeholder="Enter Your Name" />
          <input v-model="email" type="email" style="text-align: center;" required="true" placeholder="Enter Your Email Address" />
          <input v-model="password" type="password" style="text-align: center;" required="true" placeholder="Create Your Password" />
          <button class="auth" type="submit">
            {{ loading ? 'Registering...' : 'Register' }}
          </button>
  
        </form>
        <hr class="middleline">
        <p style="text-align: center;">Already Registered? Login Here.</p>
        <router-link class="routerLink" to="/login"><button class="auth" style="background-color: #000000;">Login</button></router-link>
      </div>
    </div>
  </template>
    
  
<script>
import axios from 'axios';
import NavBar from './NavBar.vue';

export default {
  name: 'SignUp',
  components: {
    NavBar,
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    async registerUser() {
      try {
        this.loading = true;
        const url = `${process.env.VUE_APP_BACKEND_URL}/api/v1/auth/register/`; // Adjust the URL as per your backend routes
        const data = {
          name: this.name,
          email: this.email,
          password: this.password,
        };
        const response = await axios.post(url, data);
        if (response.status == 201) {
          this.loading = false;
          alert(response.data.message);
          this.$router.push({ name: 'UserLogin' });
        }
      } catch (error) {
        this.loading = false;
        alert('Something went wrong');
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
  <style>
  </style>

  <style>

.signup {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 30px;
    width: 400px;
  }
  
  .register input {
    margin-top: 20px;
    width: 200px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 3px;
    border: #0d2329 sloid 1px;
  }
  
  #auth { 
    margin-top: 20px;
    width: 210px;
    height: 36px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 3px;
    border: #0d2329 sloid 1px;
    background-color: #000000;
    color: #ffffff;
    font-style: bold;
    font-size: 20px;
    
  }
  
  .register input::placeholder ,  .addnewvenue input::placeholder {
    color: #0d2329;
    font-style: bold;
  }
  
  
  .middleline{
    align-items: center;
      border-bottom: 1px solid #dadde1;
      display: flex;
      margin: 30px 16px;
      text-align: center;
      color: #dadde1;
      direction: ltr;
      line-height: 1.34;
      background-color: #dadde1;
  }
  .middleline2{
    align-items: center;
      border-bottom: 1px solid #dadde1;
      display: flex;
      margin: 10px 10px;
      text-align: center;
      color: #dadde1;
      direction: ltr;
      line-height: 1.34;
      background-color: #dadde1;
  }
  router-link{
    text-decoration: none;
  }
  
  .uservenue {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 calc(90% - 40px);
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 30px;
      margin-bottom: 30px;
      background-color: #0d2329;
      height: auto;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .signup {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    width: 400px;
    justify-items: center;
    justify-content: center;
}

.register input {
    margin-top: 20px;
    width: 200px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 3px;
    border: #0d2329 solid 1.5px;
}

.register input::placeholder {
    color: #0d2329;
    font-style: bold;
}

#registrationh1{
  width: 70%;
}


</style>