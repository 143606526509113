<template>
  <div>
    <NavBar></NavBar>
    <div class="bg-gray-100 p-8">
      <div class=" mx-auto bg-white p-6 rounded-lg shadow-lg">
        <div class="flex flex-col md:flex-row gap-6">
          <!-- Main Images Section -->
          <div class="w-full md:w-1/2 mt-8">
            <div class="relative group">
              <img ref="mainImage" :src="mainImage" alt="Main Product Image" class="product-image zoom-image mt--4">
              <div ref="result" class="result"></div>
            </div>
            <div class="relative mt-12 group">
              <img ref="mainImage2" :src="mainImage2" alt="Additional Product Image" class="product-image zoom-image">
              <div ref="result2" class="result"></div>
            </div>
          </div>

          <!-- Product Information Section -->
          <div class="w-full md:w-1/2">
            <h1 class="title">{{ styleName }}</h1>
            <p class="description">Color : {{ color }}</p>
            <h2 class="subtitle">Product Specs:</h2>
            <p>{{ construction }}</p>
            <ul class="list-disc list-inside mb-6">
              <li v-for="spec in productSpecs" :key="spec">{{ spec }}</li>
            </ul>
            <hr class="mb-6">
            <h2 class="subtitle">Care Instructions:</h2>
            <ol type="1" class="list-disc list-inside">
              <li>Using a Vacuum at light setting is recommended for routine maintenance. Please keep the Beater Bar at high setting.</li>
              <li>For minor spills and spots, applying a clean damp cotton cloth along with mild soap at the affected area may help.</li>
              <li>It is always best to consult a Professional Cleaner for heavier dirt and grime that may accumulate over years of use or persistent problems.</li>
              <li>In case of minor Pulls and Snags, a gentle snipping off of the protruding/raised yarn with scissors is usually sufficient. Any trimming of the pile should be done with care and should be even with the surface.</li>
              <li>For bigger issues, kindly contact a professional.</li>
            </ol>
          </div>
        </div>

        <hr class="my-8">
        <div>
          <h2 class="title">Color Options</h2>
          <div class="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-4">
            <div v-for="product in colorOptions" :key="product.color" class="color-option" @click="changeMainImage(product)">
              <img :src="product.image1" :alt="product.color" class="option-image">
              <p class="option-description">{{ product.color }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import axios from 'axios';

export default {
  name: 'ProductPage3',
  components: {
    NavBar
  },
  data() {
    return {
      colorOptions: [],
      styleName: 'Style Name',
      color: 'Color',
      mainImage: 'https://via.placeholder.com/300',
      mainImage2: 'https://via.placeholder.com/300',
      productSpecs: [],
      construction: 'Construction',
      baseUrl: process.env.VUE_APP_BACKEND_URL || 'http://localhost:8000',
    };
  },
  methods: {
    changeMainImage(product) {
      this.mainImage = product.image1;
      this.mainImage2 = product.image2;
      this.color = product.color;

      // Setup zoom functionality after changing images
      this.$nextTick(() => {
        this.setupZoom();
      });
    },
    setupZoom() {
      const setupImageZoom = (imgRef, resultRef) => {
        const imgElement = this.$refs[imgRef];
        const resultElement = this.$refs[resultRef];

        // Ensure result background image is updated to match the main image
        resultElement.style.backgroundImage = `url(${imgElement.src})`;
        resultElement.style.backgroundSize = `${imgElement.width * 3}px ${imgElement.height * 3}px`;

        const moveZoom = (e) => {
          const rect = imgElement.getBoundingClientRect();
          const resultWidth = resultElement.offsetWidth;
          const resultHeight = resultElement.offsetHeight;

          let x = e.pageX - rect.left - window.pageXOffset;
          let y = e.pageY - rect.top - window.pageYOffset;

          if (x > imgElement.width - resultWidth / 2) x = imgElement.width - resultWidth / 2;
          if (x < resultWidth / 2) x = resultWidth / 2;
          if (y > imgElement.height - resultHeight / 2) y = imgElement.height - resultHeight / 2;
          if (y < resultHeight / 2) y = resultHeight / 2;

          resultElement.style.left = `${x + 20}px`;
          resultElement.style.top = `${y - resultHeight / 2}px`;
          resultElement.style.backgroundPosition = `-${x * 2}px -${y * 2}px`;
        };

        imgElement.addEventListener('mousemove', moveZoom);
        imgElement.addEventListener('mouseleave', () => resultElement.style.display = 'none');
        imgElement.addEventListener('mouseenter', () => resultElement.style.display = 'block');
      };

      setupImageZoom('mainImage', 'result');
      setupImageZoom('mainImage2', 'result2');
    }
  },
  mounted() {
  // Get the styleName from URL params
  const styleName = this.$route.params.slug; // Convert to lowercase to match API style

  axios
    .get(`${this.baseUrl}/api/v1/carpet/${styleName}`)
    .then((response) => {
      const data = response.data;

      // Check if color options are available
      if (data.color_options.length === 0) {
        this.styleName = 'This Product is currently not available';
        this.color = '';
        this.mainImage = '';
        this.mainImage2 = 'https://via.placeholder.com/300';
        this.productSpecs = [];
      } else {
        this.styleName = data.style_name;
        this.construction = data.construction;
        this.colorOptions = data.color_options;
        
        // Use the first available color option
        const firstColorOption = data.color_options[0];
        this.mainImage = firstColorOption.image1 || 'https://via.placeholder.com/300';
        this.mainImage2 = firstColorOption.image2 || 'https://via.placeholder.com/300';
        this.color = firstColorOption.color;

        this.productSpecs = [
          `Contents: ${data.contents}`,
          `Pile Face Weight: ${data.pile_face_weight}`,
          `Pile Height: ${data.pile_height}`,
          `Finish: ${data.finish}`,
          `Backing: ${data.backing}`,
          `Maximum Dimensions: ${data.maximum_dimensions}`,
          `Pattern Repeat: ${data.pattern_repeat}`,
          `Price Per Unit: ${data.price_per_unit}`
        ];
      }
      
      // Ensure zoom functionality is set up correctly
      this.$nextTick(() => {
        this.setupZoom();
      });
    })
    .catch((error) => {
      console.error("Backend error:", error);
      alert("Backend Not connected");
    });
}
};
</script>

<style scoped>
.product-image {
  max-width: 100%;
  height: auto;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 40px;
}

.description {
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.color-option {
  text-align: center;
  cursor: pointer;
}

.option-image {
  max-width: 100%;
  height: auto;
}

.option-description {
  margin-top: 0.5rem;
}

.result {
  position: absolute;
  border: 1px solid #000;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
  display: none; /* Hidden by default */
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3); /* Optional: Add some shadow around the zoom effect */
}
</style>
