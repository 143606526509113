<template>
    <div>
      <h3 style="text-align: center;">Submit Image URL</h3>
      <input type="text" v-model="imageUrl" placeholder="Enter Image URL">
      <img :src="imageUrl" v-if="imageUrl" alt="Submitted Image" class="submitted-image" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'LinkSubmission',
    data() {
      return {
        imageUrl: '',
      };
    },
  };
  </script>
  
  <style scoped>
  .submitted-image {
    width: 300px;
    height: auto;
    margin-top: 20px;
  }
  </style>
  