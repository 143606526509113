<template>
    
    <NavBar></NavBar>
    <h3> Contact Page under construction.....</h3>
</template>

<script>
import NavBar from './NavBar.vue'
export default {
    name : 'ContactPage',
    components : {
        NavBar,
    },
}
</script>