<template>
  <div class="nav">
    <div class="logo">
      <div class="order-1 md:order-1">
        <a class="cursor-pointer flex my-auto justify-center font-satisfy items-center tracking-wide no-underline hover:no-underline font-bold text-white text-2xl" href="/">
          <span class="logo1">T<span style="font-size: 2.8rem;">a</span>PiZZO</span>
        </a>
      </div>
    </div>
    
    <nav class="navbar navbar-expand-md" id="navbar-color">
      <div class="container">
        <!-- Hamburger Menu Button -->
        <button class="navbar-toggler" type="button" @click="toggleSidebar">
          <svg v-if="!isSidebarOpen" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF">
            <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
          </svg>
          <svg v-if="isSidebarOpen" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF">
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
          </svg>
        </button>

        <!-- Collapsible Navbar Links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <RouterLink to="/" class="nav-link" href="#">Home</RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink to="/shop" class="nav-link">Shop</RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink to="/recommand" class="nav-link">Recommendation</RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink to="/contact" class="nav-link">Contact</RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="relative flex gap-2">
      <input type="search" name="query" id="query"
        class="block px-4 py-2 w-full text-sm text-black bg-gray-50 rounded-md border border-gray-300"
        placeholder="Search over 1000s of items..." required="" value="" />
      <button type="submit"
        class="right-2.5 top-1 my-auto border bg-white hover:bg-gray-200 text-black border-black font-futura font-semibold rounded-md text-sm px-4 py-2">
        Search
      </button>
    </div>
    <div class="nav-links">
      <RouterLink to="/profile" class="profile-link">
        <svg class="icon hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <circle fill="none" cx="12" cy="7" r="3"></circle>
          <path d="M12 2C9.243 2 7 4.243 7 7s2.243 5 5 5 5-2.243 5-5S14.757 2 12 2zM12 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3S13.654 10 12 10zM21 21v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h2v-1c0-2.757 2.243-5 5-5h4c2.757 0 5 2.243 5 5v1H21z"></path>
        </svg>
      </RouterLink>
      <a href="#" class="cart-link">
        <svg class="icon hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8
            c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z
            M17.341,14h-6.697L8.371,9h11.112L17.341,14z"></path>
          <circle cx="10.5" cy="18.5" r="1.5"></circle>
          <circle cx="17.5" cy="18.5" r="1.5"></circle>
        </svg>
      </a>
    </div>

    <!-- Sidebar -->
    <div :class="['sidebar', isSidebarOpen ? 'open' : '']">
      <ul class="sidebar-nav">
        <li class="sidebar-item">
          <RouterLink to="/" class="sidebar-link">Home</RouterLink>
        </li>
        <li class="sidebar-item">
          <RouterLink to="/shop" class="sidebar-link">Shop</RouterLink>
        </li>
        <li class="sidebar-item">
          <RouterLink to="/recommand" class="sidebar-link">Recommendation</RouterLink>
        </li>
        <li class="sidebar-item">
          <RouterLink to="/contact" class="sidebar-link">Contact</RouterLink>
        </li>
      </ul>
    </div>
  </div>
  <hr>
</template>

  
<script>
export default {
  data() {
    return {
      isSidebarOpen: false
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
  }
}
</script>

  
  <style scoped>
.nav {
  background-color: #000000;
  overflow: hidden;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  padding-bottom: 20px;
}

.logo {
  margin: 0;
}

.nav-links {
  display: flex;
  align-items: center;
}

.profile-link,
.cart-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  color: black;
  text-decoration: none;
}

.icon {
  fill: white;
}

.profile-link:hover,
.cart-link:hover {
  background-color: rgb(40, 60, 78);
}

.logo1 {
  font-family: 'Georgia', 'Times New Roman', serif;
  color: white;
  font-size: 2rem;
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%; 
  max-width: 250px; /* Optional: max width to prevent it from being too wide on larger screens */
  background-color: #33333392;
  color: white;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  z-index: 1000;
  margin-top: 80px;
  padding-left: 10%;
}

.sidebar.open {
  display: flex;
}

.close-sidebar {
  align-self: flex-end;
  margin-right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
}

.sidebar-item {
  margin: 16px 0;
}

.sidebar-link {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.sidebar-link:hover {
  text-decoration: underline;
}
</style>
