<template>
  <div>
    <NavBar></NavBar>
    <h3 style="text-align: center;">Recommendation</h3>
    <br>
    <div class="page-container">
      <div v-if="!imageUrl" class="options">
        <button @click="activateOption('upload')" :class="{ active: activeOption === 'upload' }">Image Upload</button>
        <button @click="activateOption('capture')" :class="{ active: activeOption === 'capture' }">Capture Image</button>
        <button @click="activateOption('url')" :class="{ active: activeOption === 'url' }">URL</button>
      </div>
      <div v-if="!imageUrl" class="upload-container" v-show="activeOption === 'upload'">
        <input type="file" @change="onFileChange" ref="fileInput" accept="image/*" style="display: none;">
        <button @click="triggerFileInput">Select Image</button>
      </div>
      <div class="capture-container" v-show="activeOption === 'capture'">
        <video ref="video" width="400" height="300" autoplay></video>
        <button @click="captureImage">Capture</button>
      </div>
      <div class="upload-container" v-show="activeOption === 'url'">
        <input type="text" v-model="imageUrlInput" placeholder="Enter image URL">
        <button @click="loadImageFromUrl">Load Image</button>
      </div>
      <div v-if="imageUrl" class="image-container">
        <img :src="imageUrl" alt="Uploaded Image" class="uploaded-image" />
        <div>
          <button @click="uploadFile">Upload This Picture</button>
          <button @click="resetUpload">Choose Another Picture</button>
        </div>
      </div>
      <div v-if="responsedata">
        predicted Label : {{ responsedata.predicted_label }}
        Confidence : {{ responsedata.confidence }}
        <hr>
        <div v-if="responsedata.furniture_items && responsedata.furniture_items.length > 0">
          <h4>Recommanded Products:</h4>
          <div class="furniture-items-container">
            <div v-for="item in responsedata.furniture_items" :key="item.name" class="furniture-item">
              <div>
                <b>Name:</b> {{ item.name }}, <b>Description:</b> {{ item.description }}, <b>Price:</b> {{ item.price }}
              </div>
              <div>
                <img :src="`${process.env.VUE_APP_BACKEND_URL}` + item.image" alt="Furniture Item Photo" style="max-width: 200px;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import axios from 'axios';

export default {
  name: 'RecommendationPage',
  components: {
    NavBar,
  },
  data() {
    return {
      activeOption: 'upload',
      selectedFile: null,
      imageUrl: null,
      imageUrlInput: '',
      stream: null,
      videoElement: null,
      responsedata : null,
    };
  },
  mounted() {
    this.videoElement = this.$refs.video;
  },
  methods: {
    activateOption(option) {
      this.activeOption = option;
      if (option === 'capture') {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    },
    startCamera() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          this.stream = stream;
          this.videoElement.srcObject = stream;
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
        this.videoElement.srcObject = null;
      }
    },
    captureImage() {
      const canvas = document.createElement('canvas');
      canvas.width = this.videoElement.videoWidth;
      canvas.height = this.videoElement.videoHeight;
      canvas.getContext('2d').drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);
      this.imageUrl = canvas.toDataURL('image/jpeg');
      this.selectedFile = this.dataURLtoFile(this.imageUrl, 'captured-image.jpg');
      this.stopCamera();
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    loadImageFromUrl() {
      if (this.imageUrlInput) {
        this.imageUrl = this.imageUrlInput;
        fetch(this.imageUrlInput)
          .then(res => res.blob())
          .then(blob => {
            this.selectedFile = new File([blob], 'url-image.jpg', { type: blob.type });
          });
        this.imageUrlInput = '';
      }
    },
    uploadFile() {
      if (this.selectedFile) {
        const formData = new FormData();
        formData.append('image', this.selectedFile);
        const uploadUrl = `${process.env.VUE_APP_BACKEND_URL}/api/classify/`;
        axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          console.log('Upload success:', response.data);
          this.responsedata = response.data
          alert('Uploaded successfully');
        })
        .catch(error => {
          console.error('Upload error:', error);
          alert('Upload failed');
        });
      }
    },
    resetUpload() {
      this.imageUrl = null;
      this.selectedFile = null;
      this.imageUrlInput = '';
      this.responsedata = null;
    },
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.options button {
  margin: 0 10px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.capture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploaded-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

button:hover {
  background-color: #0056b3;
}

button.active {
  background-color: #0056b3;
}

video {
  margin-bottom: 20px;
}
</style>
