<template>
  <NavBar></NavBar>
  <div class="container px-5 py-16 mx-auto" v-if="products.length">
    <div class="lg:w-4/5 mx-auto mb-3 flex flex-wrap">
      <nav class="flex">
        <ol role="list" class="flex items-center">
          <li class="text-left">
            <div class="-m-1">
              <a class="rounded-md font-futura2 p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800">Category</a>
            </div>
          </li>
          <li class="text-left">
            <div class="flex items-center">
              <span class="mx-2 font-futura2 text-gray-400">/</span>
              <div class="-m-1">
                <a class="rounded-md p-1 font-futura2 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800">
                  {{ products[selectedImageIndex].type }}
                </a>
              </div>
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="rounded-xl lg:w-1/2 w-full lg:h-auto">
        <div class="lg:flex lg:items-start">
          <div class="lg:order-2 lg:ml-5" >
            <div class="max-w-xl overflow-hidden rounded-lg">
              <img class="h-full w-full max-w-full object-cover" :src="prependBackendLink(products[selectedImageIndex].image)" alt="">
            </div>
            <div class="flex items-center justify-center">
          <qrcode-vue :value="currentUrl" :size="150" level="H" class="m-auto" />
        </div>
          </div>
          <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
            <button v-for="(product, index) in products" :key="index" type="button" @click="updateMainImage(index)"
              :class="{ 'border-blue-500': index === selectedImageIndex }"
              class="border-2 border-gray-20 flex-0 aspect-square mx-1 sm:mx-0 mb-3 h-20 overflow-hidden rounded-lg border-2 border-gray-900 text-center">
              <img class="h-full w-full object-cover" :src="prependBackendLink(product.image)" alt="">
            </button>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <qrcode-vue :value="currentUrl" :size="150" level="H" class="m-auto" />
        </div>
      </div>
      <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h2 class="text-sm font-futura text-gray-500 tracking-widest">FloorsNMore Original</h2>
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">{{ products[selectedImageIndex].title }}</h1>
        <div class="flex mb-4"></div>
        <p class="font-futura leading-relaxed">{{ products[selectedImageIndex].description }}</p>
        <p class="leading-relaxed font-futura2 mt-2">
          <span class="font-bold text-gray-800">Color: </span>{{ products[selectedImageIndex].color }}
        </p>
        <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5"></div>
        <div>
          <div class="pin flex my-2">
            <input type="number" v-model="products[selectedImageIndex].length" value="8" placeholder="Enter Length" class="border-gray-100 px-2 font-futura2 rounded-full border-2" @blur="updatePrice">
            <input type="number" v-model="products[selectedImageIndex].width" value="10" placeholder="Enter Width" class="border-gray-100 px-2 font-futura2 rounded-full border-2" @blur="updatePrice">
          </div>
          <div class="gap-2 my-2">
            <div class="flex font-futura2 gap-3 my-2">
              <del class="mt-px text-lg font-semibold text-gray-500 sm:text-xl">${{ products[selectedImageIndex].dprice }}</del>
              <span class="title-font font-medium text-2xl text-gray-900">${{ products[selectedImageIndex].oprice }}</span>
            </div>
            <div class="flex gap-2">
              <button class="align-middle select-none font-futura font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-2 sm:py-2 px-2 sm:px-6 rounded-full text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none w-full bg-black text-white text-base font-bold capitalize sm:w-auto">Buy Now</button>
              <button class="align-middle font-futura items-center text-center select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-2 sm:py-2 px-2 sm:px-6 rounded-full text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none w-full bg-black text-white text-base font-bold capitalize sm:w-auto">Add to Cart</button>
            </div>
          </div>
          <ul class="mt-8 space-y-2">
            <li class="flex font-futura2 font-normal items-center text-left text-sm font-medium text-gray-600">
              <svg class="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>Free shipping worldwide
            </li>
            <li class="flex items-center font-futura2 text-left text-sm font-medium text-gray-600">
              <svg class="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
              </svg>Cancel Anytime
            </li>
          </ul>
          <div class="pin flex my-2">
            <input type="text" placeholder="Enter your zipcode" class="border-gray-100 px-2 font-futura2 rounded-full border-2">
            <button class="align-middle select-none font-futura2 text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-2 sm:py-2 px-2 sm:px-7 mx-1 rounded-full text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none w-full bg-black text-base font-bold capitalize sm:w-auto">Check</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import QrcodeVue from "qrcode.vue";
import axios from "axios";

export default {
  props: ["type", "sku"],
  name: "ProductPage",
  components: {
    NavBar,
    QrcodeVue,
  },
  data() {
    return {
      currentUrl: "",
      products: [],
      baseUrl: process.env.VUE_APP_BACKEND_URL || "",
      selectedImageIndex: 0,
    };
  },
  methods: {
    prependBackendLink(url) {
      return this.baseUrl + url;
    },
    updateMainImage(index) {
      this.selectedImageIndex = index;
      const newSku = this.products[index].sku;
      const newUrl = `${newSku}`;
      this.$router.push(newUrl);
      this.currentUrl = newUrl;
    },
    updatePrice() {
      const product = this.products[this.selectedImageIndex];
      if (product.length > 0 && product.width > 0) {
        const length = parseFloat(product.length);
        const width = parseFloat(product.width);
        const basePrice = parseFloat(product.price);
        const originalPrice = basePrice * length * width + 10;
        product.oprice = originalPrice.toFixed(2);
        product.dprice = (originalPrice * 0.9).toFixed(2);
      }
    },
    fetchProducts() {
      axios
        .get(`${this.baseUrl}/api/products/type/${this.type}/`)
        .then((response) => {
          this.products = response.data;
          if (this.sku) {
            const selectedProductIndex = this.products.findIndex((product) => product.sku === this.sku);
            this.selectedImageIndex = selectedProductIndex !== -1 ? selectedProductIndex : 0;
          }
        })
        .catch((error) => {
          console.error("Backend error:", error);
          alert("Backend Not connected");
        });
    },
  },
  mounted() {
    this.currentUrl = window.location.href;
    this.fetchProducts();
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
